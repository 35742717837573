import React, { ReactElement } from "react";
import { GetServerSideProps } from "next";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import dynamic from "next/dynamic";

import BestRideShareLawyersWrapper from "@components/layouts/bestridesharelawyersWrapper";
import MainBanner from "@components/bestridesharelawyers/mainBanner";

const OffersList = dynamic(() => import("@components/shared/offersList"));
const MiddleSection = dynamic(
    () => import("@components/bestridesharelawyers/middleSection"),
);
const UberAccidentSection = dynamic(
    () => import("@components/bestridesharelawyers/uberAccidentSection"),
);

export default function AtmMachinePricesHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <BestRideShareLawyersWrapper>
            <MainBanner />
            <section style={{ padding: "0 15px" }}>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <MiddleSection />
            <UberAccidentSection />
        </BestRideShareLawyersWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
